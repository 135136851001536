export const client_name = [
  {
    displayText: "CTA",
    indexName: "CTA",
  },
  {
    displayText: "CTDOT",
    indexName: "CTDOT",
  },
  {
    displayText: "FDOT",
    indexName: "FDOT",
  },
  {
    displayText: "GDOT",
    indexName: "GDOT",
  },
  {
    displayText: "LA Metro",
    indexName: "LA Metro",
  },
  {
    displayText: "MBTA",
    indexName: "MBTA",
  },
  {
    displayText: "MDOT MTA",
    indexName: "MDOT MTA",
  },
  {
    displayText: "NYC MTA",
    indexName: "NYC MTA",
  },
  {
    displayText: "PANYNJ",
    indexName: "PANYNJ",
  },
  {
    displayText: "PennDOT",
    indexName: "PennDOT",
  },
  {
    displayText: "TXDOT",
    indexName: "TXDOT",
  },
  {
    displayText: "VDOT",
    indexName: "VDOT",
  },
  {
    displayText: "WMATA",
    indexName: "WMATA",
  },
  {
    displayText: "WSDOT",
    indexName: "WSDOT",
  }
];











