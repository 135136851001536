import { MutableRefObject, useEffect, useState } from "react";
import { useMorph } from "react-morph";
import Draggable from "react-draggable";
import React from "react";
import { client_name, corporate_data, end_market, geography } from "../../data";
import { ChatContextType } from "../../contexts/ChatContext/ChatContextType";
import { ChatContext } from "../../contexts/ChatContext/ChatContextProvider";
import FilterPill from "../filterpill/filterpill";
import { SearchType } from "../../enums/SearchTypes";
import { FilterScope } from "../../enums/FilterScope";
import { v4 as uuidv4 } from "uuid";
import SCFilterPill from "../scfilterpill/scfilterpill";
import { guidedPrompt } from "../../data/promptsnippets";
import { ElementPosition } from "../../enums/ElementPositions";

interface ChatQuestionDockProps {
  FollowUpQuestions: string[];
  callback: (value: string) => void;
  abortControllerRef: MutableRefObject<AbortController>[];
  setQuestionId: (value: string) => void;
  proposalSection: string;
  proposalSectionDroplistDisabled?: boolean;
}

const ChatQuestionDock: React.FC<ChatQuestionDockProps> = ({
  FollowUpQuestions,
  callback,
  abortControllerRef,
  setQuestionId,
  proposalSection,
  proposalSectionDroplistDisabled,
}) => {
  const chatContext = React.useContext<ChatContextType | null>(ChatContext);
  const [allSelected, setAllSelected] = useState<boolean>(
    chatContext ? chatContext!.allSelected : true
  );
  const [inputValue, setInputValue] = useState<string>(
    chatContext?.chat.question ?? ""
  );
  const [submitReady, setSubmitReady] = useState<boolean>(false);
  const [appliedFilters, setAppliedFilters] = useState<string[]>(
    chatContext ? chatContext!.indexes : []
  );
  const [filterScope, setFilterScope] = useState<FilterScope>();
  const [openFilters, setOpenFilters] = useState<string[]>([]);
  const [openGuidedPrompt, setOpenGuidedPrompt] = useState<string[]>([]);
  const [pillname, setPillname] = useState<string>(
    chatContext?.proposalSectionType ?? "Guided Prompts"
  );
  const [appliedLocalFilter, setAppliedLocalFilter] = useState<string>(
    chatContext?.proposalSectionType ?? "Guided Prompts"
  );
  useEffect(() => {
    if (chatContext && chatContext.indexes) {
      setAppliedFilters(chatContext!.indexes);

      if (
        chatContext!.indexes.length <
        [...client_name, ...corporate_data, ...end_market, ...geography].length
      ) {
        setAllSelected(false);
      }
    }
  }, [chatContext && chatContext.indexes]);

  useEffect(() => {
    if (inputValue.trim().length > 0) {
      setSubmitReady(true);
    } else {
      setSubmitReady(false);
    }
  }, [inputValue]);

  const handleUserChange = (value: string) => {
    setInputValue(value);
  };

  const addToAppliedFilters = (value: string, startFresh: boolean) => {
    let newFilters = [...appliedFilters];

    if (startFresh) {
      newFilters = [];
    }

    const newValues = value.split(",");

    newValues.forEach((newValue) => {
      const index = newFilters.indexOf(newValue, 0);
      if (index < 0) {
        newFilters.push(newValue);
      }
    });

    setAppliedFilters(newFilters);
  };

  const removeFromAppliedFilters = (value: string) => {
    const newFilters = [...appliedFilters];

    const newValues = value.split(",");
    newValues.forEach((newValue) => {
      do {
        const index = newFilters.indexOf(newValue, 0);
        if (index > -1) {
          newFilters.splice(index, 1);
        }
      } while (newFilters.indexOf(newValue, 0) > -1);
    });

    if (newFilters.length < 1) {
      setAppliedFilters(
        [...client_name, ...corporate_data, ...end_market, ...geography].map(
          (item) => item.indexName
        )
      );
      chatContext?.saveAllSelected(true);
      setAllSelected(true);
    } else {
      setAppliedFilters(newFilters);
    }
  };

  const handleAddToPrompt = (text: string, value: string) => {
    setInputValue(value);
    chatContext?.saveProposalSectionType(text);
  };

  return (
    <>
      <div>
        <div className="grid grid-cols-1 gap-4 p-4 drop-shadow-lg rounded-b">
          <div>
            <div className="mb-2">
              <div className="flex flex-row gap-2">
                <div>
                  <button
                    type="button"
                    className={
                      chatContext?.searchType === SearchType.Kickstart
                        ? "px-2 py-4 font-semibold border-b-4 border-blue"
                        : "px-2 py-4 font-semibold"
                    }
                    onClick={() => {
                      if (chatContext?.searchType !== SearchType.Kickstart) {
                        chatContext?.saveSearchType(SearchType.Kickstart);
                        // Reset guided prompt text
                        setInputValue("");
                      }
                    }}
                  >
                    {SearchType.Kickstart}
                  </button>
                </div>
                <div>
                  <button
                    type="button"
                    className={
                      chatContext?.searchType === SearchType.ProposalSections
                        ? "px-2 py-4 font-semibold border-b-4 border-blue"
                        : "px-2 py-4 font-semibold"
                    }
                    onClick={() => {
                      if (
                        chatContext?.searchType !== SearchType.ProposalSections
                      ) {
                        chatContext?.saveSearchType(
                          SearchType.ProposalSections
                        );
                        // Reset guided prompt text
                        setInputValue("");
                      }
                    }}
                  >
                    {SearchType.ProposalSections}
                  </button>
                </div>
              </div>
            </div>
            <div className="bg-white border border-light-gray-40 p-2">
              <div>
                <textarea
                  className="w-full resize-none text-charcoal-dark"
                  rows={5}
                  placeholder="Ask a follow up."
                  value={inputValue}
                  onChange={(e) => handleUserChange(e.target.value)}
                  onFocus={() => setOpenFilters([])}
                ></textarea>
              </div>
              <div className="flex flex-row">
                <div className="flex-1 flex flex-row text-xs gap-1">
                  <div>
                    <div className="flex flex-wrap gap-1">
                      <div className="flex my-auto">Filters:</div>

                      {/* All Resources */}
                      <div
                        className={
                          allSelected
                            ? "border border-blue bg-blue text-white rounded-full my-auto"
                            : "border border-blue rounded-full my-auto"
                        }
                      >
                        <button
                          type="button"
                          className="p-2"
                          onClick={() => {
                            setAllSelected(true);
                            chatContext?.saveAllSelected(true);
                            setAppliedFilters(
                              [
                                ...client_name,
                                ...corporate_data,
                                ...end_market,
                                ...geography,
                              ].map((item) => item.indexName + ",")
                            );
                          }}
                        >
                          All Resources
                        </button>
                      </div>

                      {/* Divider */}
                      <div className="border-r border-light-gray pl-1 mr-1 my-1"></div>

                      <FilterPill
                        pillname="Client Name"
                        dropdownPosition={ElementPosition.Top}
                        data={client_name}
                        appliedFilters={appliedFilters}
                        addCallback={addToAppliedFilters}
                        removeCallback={removeFromAppliedFilters}
                        openFilters={openFilters}
                        setOpenFilters={setOpenFilters}
                        allSelected={allSelected}
                        setAllSelected={(value: boolean) => {
                          setAllSelected(value);
                          chatContext?.saveAllSelected(value);
                        }}
                      />

                      <FilterPill
                        pillname="Geography"
                        dropdownPosition={ElementPosition.Top}
                        data={geography}
                        appliedFilters={appliedFilters}
                        addCallback={addToAppliedFilters}
                        removeCallback={removeFromAppliedFilters}
                        openFilters={openFilters}
                        setOpenFilters={setOpenFilters}
                        allSelected={allSelected}
                        setAllSelected={(value: boolean) => {
                          setAllSelected(value);
                          chatContext?.saveAllSelected(value);
                        }}
                      />

                      <FilterPill
                        pillname="End Market"
                        dropdownPosition={ElementPosition.Top}
                        data={end_market}
                        appliedFilters={appliedFilters}
                        addCallback={addToAppliedFilters}
                        removeCallback={removeFromAppliedFilters}
                        openFilters={openFilters}
                        setOpenFilters={setOpenFilters}
                        allSelected={allSelected}
                        setAllSelected={(value: boolean) => {
                          setAllSelected(value);
                          chatContext?.saveAllSelected(value);
                        }}
                      />

                      <FilterPill
                        pillname="Corporate Data"
                        dropdownPosition={ElementPosition.Top}
                        data={corporate_data}
                        appliedFilters={appliedFilters}
                        addCallback={addToAppliedFilters}
                        removeCallback={removeFromAppliedFilters}
                        openFilters={openFilters}
                        setOpenFilters={setOpenFilters}
                        allSelected={allSelected}
                        setAllSelected={(value: boolean) => {
                          setAllSelected(value);
                          chatContext?.saveAllSelected(value);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="content-end">
                  <button
                    type="button"
                    className={
                      submitReady
                        ? "bg-blue text-white w-full p-2"
                        : "bg-disabled text-white w-full p-2"
                    }
                    disabled={!submitReady}
                    onClick={() => {
                      chatContext?.saveChatAndIndexes(
                        {
                          question: inputValue.trim(),
                          history: "",
                          proposal: proposalSection,
                        },
                        appliedFilters,
                        filterScope
                      );
                      setQuestionId(uuidv4().replaceAll("-", ""));
                      abortControllerRef.forEach(
                        (item) => (item.current = new AbortController())
                      );
                    }}
                  >
                    <svg
                      width="18"
                      height="16"
                      viewBox="0 0 18 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1.925 3.025L8.18333 5.70833L1.91667 4.875L1.925 3.025ZM8.175 10.2917L1.91667 12.975V11.125L8.175 10.2917ZM0.258333 0.5L0.25 6.33333L12.75 8L0.25 9.66667L0.258333 15.5L17.75 8L0.258333 0.5Z"
                        fill="white"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {chatContext?.searchType === SearchType.ProposalSections && (
          <div className="mx-0">
            <div className="grid grid-cols-1 gap-4 mx-5">
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                <SCFilterPill
                  pillname={pillname}
                  dropdownPosition={ElementPosition.Top}
                  data={guidedPrompt}
                  callback={handleAddToPrompt}
                  openFilters={openGuidedPrompt}
                  setOpenFilters={setOpenGuidedPrompt}
                  appliedLocalFilter={appliedLocalFilter}
                  setAppliedLocalFilter={setAppliedLocalFilter}
                  disabled={proposalSectionDroplistDisabled}
                />
              </div>
              <div className="pb-2"></div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ChatQuestionDock;
