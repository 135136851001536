export const end_market = [
  {
    displayText: "Construction Management",
    indexName: "Construction Management",
  },
  {
    displayText: "Mobility Operations",
    indexName: "Mobility Operations",
  },
  {
    displayText: "Water",
    indexName: "Water",
  },
  {
    displayText: "Transportation",
    indexName: "Transportation",
  },
  {
    displayText: "Highway and Tolling",
    indexName: "Highway and Tolling",
  },
  {
    displayText: "Structures",
    indexName: "Structures",
  },
  {
    displayText: "Maritime",
    indexName: "Maritime",
  },
  {
    displayText: "Aviation",
    indexName: "Aviation",
  },
  {
    displayText: "Geotech and Tunneling",
    indexName: "Geotech and Tunneling",
  },
  {
    displayText: "Transit and Rail",
    indexName: "Transit and Rail",
  }
];


 






