import React, { useEffect, useState } from "react";
import { SnippetButton, WSPRule } from "../../components";
import { SnippetType } from "../../enums/SnippetType";
import { ChatContextType } from "../../contexts/ChatContext/ChatContextType";
import { ChatContext } from "../../contexts/ChatContext/ChatContextProvider";
import { useNavigate } from "react-router-dom";
import { FilterPill, SCFilterPill } from "../../components";
import { client_name, geography, end_market, corporate_data } from "../../data";
import { SearchType } from "../../enums/SearchTypes";
import { FilterScope } from "../../enums/FilterScope";
import { guidedPrompt } from "../../data/promptsnippets";
import { Placeholders } from "../../enums/Placeholders";

const Home = () => {
  const chatContext = React.useContext<ChatContextType | null>(ChatContext);
  const [inputValue, setInputValue] = useState<string>(
    chatContext ? chatContext!.chat.question : ""
  );
  const [proposalSectionInputValue, setProposalSectionInputValue] =
    useState<string>(chatContext ? chatContext!.chat.proposal : "");
  const [submitReady, setSubmitReady] = useState<boolean>(false);
  const [allSelected, setAllSelected] = useState<boolean>(
    chatContext ? chatContext!.allSelected : true
  );
  const [appliedFilters, setAppliedFilters] = useState<string[]>(
    chatContext ? chatContext!.indexes : []
  );

  const [openFilters, setOpenFilters] = useState<string[]>([]);
  const [filterScope, setFilterScope] = useState<FilterScope>();
  const [openGuidedPrompt, setOpenGuidedPrompt] = useState<string[]>([]);
  const navigate = useNavigate();
  const [pillname, setPillname] = useState<string>("Guided Prompts");
  const [appliedLocalFilter, setAppliedLocalFilter] = useState<string>("");
  // Can only submit when value in user input field.
  useEffect(() => {
    if (inputValue.trim().length > 0) {
      setSubmitReady(true);
    } else {
      setSubmitReady(false);
    }
  }, [inputValue]);

  const handleUserChange = (value: string) => {
    setInputValue(value);
  };

  const handleProposalSectionChange = (value: string) => {
    setProposalSectionInputValue(value);
  };

  const handleAddToPrompt = (text: string, value: string) => {
    setInputValue(value);
    chatContext?.saveProposalSectionType(text);
  };

  const handleSubmit = () => {
    chatContext?.saveChatAndIndexes(
      {
        question: inputValue.trim(),
        history: "",
        proposal: proposalSectionInputValue.trim(),
      },
      appliedFilters,
      filterScope
    );
    navigate("/chat");
  };

  const addToAppliedFilters = (value: string, startFresh: boolean) => {
    let newFilters = [...appliedFilters];

    if (startFresh) {
      newFilters = [];
    }

    const newValues = value.split(",");

    newValues.forEach((newValue) => {
      const index = newFilters.indexOf(newValue, 0);
      if (index < 0) {
        newFilters.push(newValue);
      }
    });

    setAppliedFilters(newFilters);
    chatContext?.saveIndexes(newFilters);
  };

  const removeFromAppliedFilters = (value: string) => {
    const newFilters = [...appliedFilters];

    const newValues = value.split(",");
    newValues.forEach((newValue) => {
      do {
        const index = newFilters.indexOf(newValue, 0);
        if (index > -1) {
          newFilters.splice(index, 1);
        }
      } while (newFilters.indexOf(newValue, 0) > -1);
    });

    if (newFilters.length < 1) {
      setAppliedFilters(
        [...client_name, ...corporate_data, ...end_market, ...geography].map(
          (item) => item.indexName
        )
      );
      chatContext?.saveAllSelected(true);
      setAllSelected(true);
    } else {
      setAppliedFilters(newFilters);
    }

    chatContext?.saveIndexes(newFilters);
  };

  const closeAllFilters = () => {
    setOpenFilters([]);
  };

  return (
    <div className="flex flex-col w-full">
      <div className="pt-10 bg-cover bg-center bg-no-repeat">
        <div className="py-5 px-5">
          <div className="pb-6">
            <h2 className="text-blue">
              tell me about <b>your proposal</b> to get started.
            </h2>
          </div>
          <div className="mb-2">
            <div className="flex flex-row gap-2">
              <div>
                <button
                  type="button"
                  className={
                    chatContext?.searchType === SearchType.Kickstart
                      ? "px-2 py-4 font-semibold border-b-4 border-blue"
                      : "px-2 py-4 font-semibold"
                  }
                  onClick={() => {
                    if (chatContext?.searchType !== SearchType.Kickstart) {
                      chatContext?.saveSearchType(SearchType.Kickstart);
                      // Reset guided prompt text
                      setInputValue("");
                    }
                  }}
                >
                  {SearchType.Kickstart}
                </button>
              </div>
              <div>
                <button
                  type="button"
                  className={
                    chatContext?.searchType === SearchType.ProposalSections
                      ? "px-2 py-4 font-semibold border-b-4 border-blue"
                      : "px-2 py-4 font-semibold"
                  }
                  onClick={() => {
                    if (
                      chatContext?.searchType !== SearchType.ProposalSections
                    ) {
                      chatContext?.saveSearchType(SearchType.ProposalSections);
                      // Reset guided prompt text
                      setInputValue("");
                      // Reset prompt dropdown
                      setPillname("Guided Prompts");
                      setOpenGuidedPrompt([]);
                      setAppliedLocalFilter("");
                    }
                  }}
                >
                  {SearchType.ProposalSections}
                </button>
              </div>
            </div>
          </div>
          <div className="mb-2">
            <div className="bg-white border border-light-gray-40 p-2">
              <div>
                <textarea
                  className="w-full resize-none text-charcoal-dark"
                  rows={7}
                  placeholder={Placeholders.RFPPlaceholder}
                  value={proposalSectionInputValue}
                  onChange={(e) => handleProposalSectionChange(e.target.value)}
                  onFocus={() => closeAllFilters()}
                ></textarea>
              </div>
            </div>
          </div>
          <div>
            <div className="bg-white border border-light-gray-40 p-2">
              <div>
                <textarea
                  className="w-full resize-none text-charcoal-dark"
                  rows={6}
                  placeholder={Placeholders.PromptPlaceholder}
                  value={inputValue}
                  onChange={(e) => handleUserChange(e.target.value)}
                  onFocus={() => closeAllFilters()}
                ></textarea>
              </div>
              <div className="flex flex-row text-charcoal-dar">
                <div className="flex flex-1 text-xs m-auto gap-1 flex-wrap">
                  <div className="my-auto">Filters:</div>

                  {/* All Resources */}
                  <div
                    className={
                      allSelected
                        ? "border border-blue bg-blue text-white rounded-full my-auto"
                        : "border border-blue rounded-full my-auto"
                    }
                  >
                    <button
                      type="button"
                      className="p-2"
                      onClick={() => {
                        setAllSelected(true);
                        chatContext?.saveAllSelected(true);
                        setAppliedFilters(
                          [
                            ...client_name,
                            ...corporate_data,
                            ...end_market,
                            ...geography,
                          ].map((item) => item.indexName)
                        );
                      }}
                    >
                      All Resources
                    </button>
                  </div>

                  {/* Divider */}
                  <div className="border-r border-light-gray pl-1 mr-1 my-1"></div>

                  <FilterPill
                    pillname="Client Name"
                    data={client_name}
                    appliedFilters={appliedFilters}
                    addCallback={addToAppliedFilters}
                    removeCallback={removeFromAppliedFilters}
                    openFilters={openFilters}
                    setOpenFilters={setOpenFilters}
                    allSelected={allSelected}
                    setAllSelected={(value: boolean) => {
                      setAllSelected(value);
                      chatContext?.saveAllSelected(value);
                    }}
                  />

                  <FilterPill
                    pillname="Geography"
                    data={geography}
                    appliedFilters={appliedFilters}
                    addCallback={addToAppliedFilters}
                    removeCallback={removeFromAppliedFilters}
                    openFilters={openFilters}
                    setOpenFilters={setOpenFilters}
                    allSelected={allSelected}
                    setAllSelected={(value: boolean) => {
                      setAllSelected(value);
                      chatContext?.saveAllSelected(value);
                    }}
                  />

                  <FilterPill
                    pillname="End Market"
                    data={end_market}
                    appliedFilters={appliedFilters}
                    addCallback={addToAppliedFilters}
                    removeCallback={removeFromAppliedFilters}
                    openFilters={openFilters}
                    setOpenFilters={setOpenFilters}
                    allSelected={allSelected}
                    setAllSelected={(value: boolean) => {
                      setAllSelected(value);
                      chatContext?.saveAllSelected(value);
                    }}
                  />

                  <FilterPill
                    pillname="Corporate Data"
                    data={corporate_data}
                    appliedFilters={appliedFilters}
                    addCallback={addToAppliedFilters}
                    removeCallback={removeFromAppliedFilters}
                    openFilters={openFilters}
                    setOpenFilters={setOpenFilters}
                    allSelected={allSelected}
                    setAllSelected={(value: boolean) => {
                      setAllSelected(value);
                      chatContext?.saveAllSelected(value);
                    }}
                  />
                </div>
                <div className="mt-auto">
                  <button
                    type="button"
                    className={
                      submitReady
                        ? "bg-blue text-white w-full p-2"
                        : "bg-disabled text-white w-full p-2"
                    }
                    disabled={!submitReady}
                    onClick={handleSubmit}
                  >
                    <svg
                      width="18"
                      height="16"
                      viewBox="0 0 18 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1.925 3.025L8.18333 5.70833L1.91667 4.875L1.925 3.025ZM8.175 10.2917L1.91667 12.975V11.125L8.175 10.2917ZM0.258333 0.5L0.25 6.33333L12.75 8L0.25 9.66667L0.258333 15.5L17.75 8L0.258333 0.5Z"
                        fill="white"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {chatContext?.searchType === SearchType.ProposalSections && (
        <div className="mx-0">
          <div className="grid grid-cols-1 gap-4 mx-5">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
              <SCFilterPill
                pillname={pillname}
                data={guidedPrompt}
                callback={handleAddToPrompt}
                openFilters={openGuidedPrompt}
                setOpenFilters={setOpenGuidedPrompt}
                appliedLocalFilter={appliedLocalFilter}
                setAppliedLocalFilter={setAppliedLocalFilter}
              />
            </div>
            <div className="pb-2"></div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Home;
