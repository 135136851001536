import { useEffect, useState } from "react";
import React from "react";
import { PromptSnippet } from "../../models/promptsnippet";
import { ElementPosition } from "../../enums/ElementPositions";
import { ChatContextType } from "../../contexts/ChatContext/ChatContextType";
import { ChatContext } from "../../contexts/ChatContext/ChatContextProvider";

interface FilterPillProps {
  pillname: string;
  dropdownPosition?: ElementPosition;
  data: PromptSnippet[];
  callback: (text: string, value: string) => void;
  openFilters: string[];
  setOpenFilters: (openFilters: string[]) => void;
  appliedLocalFilter: string;
  setAppliedLocalFilter: (appliedLocalFilter: string) => void;
  showDivider?: boolean;
  disabled?: boolean;
}
/** Single-Choice (SC) Filter Pill*/
const SCFilterPill: React.FC<FilterPillProps> = ({
  pillname,
  dropdownPosition,
  data,
  callback,
  openFilters,
  setOpenFilters,
  appliedLocalFilter,
  setAppliedLocalFilter,
  showDivider,
  disabled,
}) => {
  const chatContext = React.useContext<ChatContextType | null>(ChatContext);
  const [visible, setVisible] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<string | null>(null);
  const [hoveredItem, setHoveredItem] = useState<string | null>(null);

  // Function to handle item selection
  const handleSelection = (text: string, value: string) => {
    if (selectedItem === value) {
      // If the item is already selected, deselect it
      setSelectedItem(null);
      removeFromAppliedLocalFilters();
      callback("", "");
    } else {
      // Otherwise, select the new item and close the filter and update selection
      setSelectedItem(value);
      addToAppliedLocalFilters(text);
      callback(text, value);
    }
  };

  useEffect(() => {
    if (appliedLocalFilter && chatContext && chatContext.proposalSectionType) {
      const currentItemValue = data.find(
        (item) => item.text === chatContext.proposalSectionType
      );

      if (currentItemValue) {
        setSelectedItem(currentItemValue.value);
        addToAppliedLocalFilters(currentItemValue.text);
      }
    }
  }, [chatContext?.proposalSectionType]);

  useEffect(() => {
    const index = openFilters.indexOf(pillname, 0);
    if (index > -1) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  }, [openFilters, pillname]);

  useEffect(() => {
    if (pillname && data.find((item) => item.text === pillname)) {
      setAppliedLocalFilter(pillname);
    }
  }, [pillname]);

  // Function to add value from Applied Local Filters
  const addToAppliedLocalFilters = (text: string) => {
    setAppliedLocalFilter(text);
  };

  // Function to remove value from Applied Local Filters
  const removeFromAppliedLocalFilters = () => {
    setAppliedLocalFilter("");
  };

  // Function to close item selection
  const changeVisibility = () => {
    setVisible(!visible);
    if (!visible) {
      setOpenFilters([pillname]);
    }
  };
  const localCompare = (a: string, b: string) => {
    if (a === "None") return -1;
    if (b === "None") return 0;
    return a.localeCompare(b);
  };

  const determinePositionClasses = (
    dropdownPosition: ElementPosition | undefined
  ) => {
    let returnValue = "";

    if (dropdownPosition !== undefined) {
      switch (dropdownPosition) {
        case ElementPosition.Top:
          returnValue = "bottom-[64px]";
          break;
        case ElementPosition.Right:
          returnValue = "left-[190px] top-0";
          break;
        case ElementPosition.Left:
          returnValue = "right-[190px] top-0";
          break;
      }
    }

    return returnValue;
  };

  return (
    <div
      className={
        appliedLocalFilter
          ? "relative border border-blue my-auto bg-blue"
          : "relative border border-blue my-auto"
      }
    >
      <div className={appliedLocalFilter ? "p-2 text-white" : "p-2"}>
        <button
          type="button"
          style={{
            display: "flex" /* Enable Flexbox */,
            justifyContent:
              "space-between" /* Aligns children to the left and right */,
            alignItems: "center" /* Centers children vertically */,
            padding: "5px 5px" /* Adjust padding as needed */,
            width: "100%" /* Full width (optional) */,
          }}
          onClick={() => changeVisibility()}
          disabled={disabled}
        >
          <span>
            {appliedLocalFilter
              ? data.find((item) => item.text === appliedLocalFilter)
                  ?.displayName ??
                data.find((item) => item.text === appliedLocalFilter)?.text
              : pillname}
          </span>
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="stroke-current stroke-0"
              width="8"
              height="8"
              fill="currentColor"
              viewBox="0 0 8 4"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
                d="M0.666016 0.333252L3.99935 3.66659L7.33268 0.333252H0.666016Z"
              />
            </svg>
          </div>
        </button>
      </div>
      <div
        id={"dropdown"}
        className={
          determinePositionClasses(dropdownPosition) +
          " " +
          (visible
            ? "mt-px absolute p-1 bg-white shadow-md flex flex-col gap-2 w-[250px] options-scroll"
            : "mt-px absolute p-1 bg-white shadow-md flex flex-col gap-2 w-[250px] options-scroll hidden")
        }
      >
        <div
          style={{
            maxHeight: "300px", // Set max height to enable scrolling
            overflowY: "auto", // Enable vertical scrolling
            //scrollbarWidth: "none",
          }}
        >
          {/* Options */}
          {data
            //.sort((a, b) => localCompare(a.text, b.text)) // we do not sort it for now, we used the pre-designed order
            .map((item, index) => (
              <div className="flex-row gap-1" key={item.value}>
                <React.Fragment key={index}>
                  <button
                    key={item.value}
                    onClick={() => {
                      handleSelection(item.text, item.value);
                      changeVisibility();
                    }}
                    onMouseEnter={() => setHoveredItem(item.value)}
                    onMouseLeave={() => setHoveredItem(null)}
                    style={{
                      width: "100%",
                      padding: "10px",
                      backgroundColor:
                        selectedItem === item.value ||
                        hoveredItem === item.value
                          ? "#d8e5f0"
                          : "white",
                      color:
                        selectedItem === item.value ||
                        hoveredItem === item.value
                          ? "black"
                          : "black",
                      border: "none",
                      borderRadius: "0px",
                      cursor: "hand",
                      transition: "background-color 0.3s ease",
                      textAlign: "left",
                    }}
                  >
                    {item.displayName ?? item.text}
                  </button>
                  {index < data.length - 1 && (
                    <div
                      style={{
                        height: showDivider ? "1px" : "0px",
                        backgroundColor: "#d8e5f0",
                        width: "100%",
                        alignSelf: "center",
                      }}
                    />
                  )}
                </React.Fragment>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default SCFilterPill;
