import { client_name, corporate_data, end_market, geography } from "../data";
import { SearchFilterTypes } from "../enums/SearchFilterTypes";

export function getIndexesBySearchFilterTypes(
    indexes: string,
    searchFilterType: SearchFilterTypes
  ): string {
    const indexArray = indexes.split(",");
    let result = [];
    if (indexArray !== null && indexArray.length > 0) {
      if (searchFilterType === SearchFilterTypes.CLIENT_NAME) {
        result = indexArray.filter((index) =>
          client_name.find((item) => item.indexName === index)
        );
        return result.join(",");
      }
      if (searchFilterType === SearchFilterTypes.CORPORATE_CONTENT) {
        result = indexArray.filter((index) =>
          corporate_data.find((item) => item.indexName === index)
        );
        return result.join(",");
      }
      if (searchFilterType === SearchFilterTypes.GEOGRAPHY) {
        result = indexArray.filter((index) =>
          geography.find((item) => item.indexName === index)
        );
        return result.join(",");
      }
      if (searchFilterType === SearchFilterTypes.PRIMARY_END_MARKET) {
        result = indexArray.filter((index) =>
          end_market.find((item) => item.indexName === index)
        );
        return result.join(",");
      }
    }
  
    return "";
  }