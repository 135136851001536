import { useContext, useEffect, useRef, useState } from "react";
import {
  msalInstance as msalInstanceAAD,
  getAadAuthToken as getAuthToken,
} from "../components/authenticator/azureAAD/Authenticator";
import { mockResponse3 } from "../data/mockresponse3";
import { mockResponse5 } from "../data/mockresponse5";
import { ChatContextType } from "../contexts/ChatContext/ChatContextType";
import { ChatContext } from "../contexts/ChatContext/ChatContextProvider";
import { SearchType } from "../enums/SearchTypes";
import { AIChatResponse } from "../models/aichatresponse";
import { SearchFilterTypes } from "../enums/SearchFilterTypes";
import { client_name, corporate_data, end_market, geography } from "../data";
import { AIChatResponseHistory } from "../models/aichatresponsehistory";
import { SectionTabs } from "../enums/SectionTabs";
import { ErrorMessage } from "../enums/ErrorMessage";

interface StreamState {
  data: Uint8Array | null;
  error: Error | null;
  controller: AbortController;
}

function useGetAIChatResponse(
  message: string,
  history: string,
  proposal: string,
  indexes: string[],
  sessionId: string,
  questionId: string
) {
  const chatContext = useContext<ChatContextType | null>(ChatContext);
  const [data, setData] = useState<AIChatResponse | null>(null);
  const [loading, setLoading] = useState(false);
  const abortControllerRef = useRef<AbortController>(new AbortController());
  const [streaming, setStreaming] = useState(false);
  const [streamResponse, setStreamResponse] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  const [state, setState] = useState<StreamState>({
    data: null,
    error: null,
    controller: new AbortController(),
  });

  const decoder = new TextDecoder();

  useEffect(() => {
    (async () => {
      if (message.length > 0) {
        const messageText = JSON.stringify(
          message.replace(/\r\n|\n|\r/gi, " ")
        );
        const searchType = chatContext?.searchType === SearchType.Kickstart ? SectionTabs.Kickstart : SectionTabs.ProposalSections;
        try {
          setLoading(true);

          const body =
            `{
      "role": "user",
      "content": ` +
            messageText +
            `,
      "history": ` +
            history +
            `,
      "region": "` +
            getIndexesBySearchFilterTypes(
              indexes.toString(),
              SearchFilterTypes.GEOGRAPHY
            ) +
            `", 
      "client_name": "` +
            getIndexesBySearchFilterTypes(
              indexes.toString(),
              SearchFilterTypes.CLIENT_NAME
            ) +
            `", 
      "primary_end_market": "` +
            getIndexesBySearchFilterTypes(
              indexes.toString(),
              SearchFilterTypes.PRIMARY_END_MARKET
            ) +
            `", 
      "corporate_content": "` +
            getIndexesBySearchFilterTypes(
              indexes.toString(),
              SearchFilterTypes.CORPORATE_CONTENT
            ) +
            `", 
      "proposal_section": ` +
            JSON.stringify(proposal.replace(/\r\n|\n|\r/gi, " ")) +
            `,
      "proposal_section_type": "` +
            chatContext?.proposalSectionType +
            `",  
      "section_tab": "` +
            searchType +    
            `",  
      "session_id": "` +
            sessionId +
            `"}`;

          const authToken = await getAuthToken();
          const resp = await fetch(process.env.REACT_APP_CHAT_COMPLETION_URL!, {
            method: "POST",
            signal: abortControllerRef.current.signal,
            headers: {
              Authorization: `Bearer ${authToken}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
            },
            body,
          });
          if (!resp.ok || !resp.body) {
            throw resp.statusText;
          }

          let chunks = "";
          let thisChunk = "";
          const reader = resp.body.getReader();
          while (true) {
            setLoading(false);
            setStreaming(true);
            const { done, value } = await reader.read();

            if (done) {
              break;
            }

            thisChunk = decoder.decode(value);

            chunks += thisChunk;

            setStreamResponse(chunks);
          }
          
          // When there's no response from chatcompleteion and it returns 200, or return 403 Forbidden, 
          // or 404 Not Found, 
          // or 400 Bad Request
          // an error message should will be shown in the UI.
          if((resp.status === 200 && (!chunks.split("|")[0] || !chunks)) || (resp.status >= 400 && resp.status <= 404)) {
            throw ErrorMessage.NoResponse
          }

          const finalData: AIChatResponse = {
            question: message,
            output: chunks.split("|")[0],
            history: [],
            session_id: sessionId,
          } as AIChatResponse;

          setStreaming(false);
          setData(finalData);
        } catch (err) {
          console.log("Chat completion error");
          console.log(err);

          if(err === ErrorMessage.NoResponse) {
            setError(ErrorMessage.NoResponse);
          } else if (abortControllerRef.current.signal.aborted) {
            console.log("Abort abortControllerRef ref");
            console.log(abortControllerRef);
            setError(ErrorMessage.ResponseStopped);
          } else {
            setError("The ai chat response has been stopped.");
          }

          setStreaming(false);
          setLoading(false);
        }
      }
    })();

    return () => state.controller.abort();
  }, [message, indexes, proposal, sessionId]);

  return {
    data,
    loading,
    abortControllerRef,
    streaming,
    streamResponse,
    error: error,
  };
}

function getIndexesBySearchFilterTypes(
  indexes: string,
  searchFilterType: SearchFilterTypes
): string {
  const indexArray = indexes.split(",");
  let result = [];
  if (indexArray !== null && indexArray.length > 0) {
    if (searchFilterType === SearchFilterTypes.CLIENT_NAME) {
      result = indexArray.filter((index) =>
        client_name.find((item) => item.indexName === index)
      );
      return result.join(",");
    }
    if (searchFilterType === SearchFilterTypes.CORPORATE_CONTENT) {
      result = indexArray.filter((index) =>
        corporate_data.find((item) => item.indexName === index)
      );
      return result.join(",");
    }
    if (searchFilterType === SearchFilterTypes.GEOGRAPHY) {
      result = indexArray.filter((index) =>
        geography.find((item) => item.indexName === index)
      );
      return result.join(",");
    }
    if (searchFilterType === SearchFilterTypes.PRIMARY_END_MARKET) {
      result = indexArray.filter((index) =>
        end_market.find((item) => item.indexName === index)
      );
      return result.join(",");
    }
  }

  return "";
}

export default useGetAIChatResponse;
