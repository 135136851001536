import { PinnedChat } from "../models/pinnedchat";
import { msalInstance } from "../components/authenticator/azureAAD/Authenticator";

function submitChatHistory(
  chatHistory: PinnedChat,
  section_tab: string,
  proposal_section_type?: string,
  proposal_section?: string
): boolean {
  let returnValue = true;

  if (chatHistory.chatMessages.length > 0) {
    const accounts = msalInstance.getAllAccounts();
    const userId = accounts[0].username;

    const body =
      `{
      "user_id": "` +
      userId +
      `",
      "chat_messages": ` +
      JSON.stringify(chatHistory.chatMessages) +
      `,"section_tab":"` +
      section_tab +
      `", "proposal_section": "` +
      proposal_section +
      `",
      "proposal_section_type":"` +
      proposal_section_type +
      `"
      }`;

    // console.log("Submit Chat History");
    // console.log(body);

    (async () => {
      try {
        const resp = await fetch(process.env.REACT_APP_CHAT_SAVE_URL!, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
          body,
        });
        if (!resp.ok || !resp.body) {
          throw resp.statusText;
        }

        returnValue = true;
      } catch (err) {
        console.log("persist chat history error");
        console.log(err);
        returnValue = false;
      }
    })();
  }

  return returnValue;
}

export default submitChatHistory;
