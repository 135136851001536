interface ProposalCardProps {
  key: string;
  displayText: string;
  returnURL: string;
  pageNumber?: string;
  callback: (value: string) => void;
}

const ProposalCard: React.FC<ProposalCardProps> = ({
  key,
  displayText,
  returnURL,
  pageNumber,
  callback,
}) => {
  return (
    <div
      className="bg-light-blue p-6 whitespace-nowrap flex flex-col gap-2 my-auto min-w-0"
      key={key}
    >
      <div>
        <div className="flex flex-row gap-2 min-w-0">
          <div className="grow min-w-0">
            <button
              type="button"
              className="w-full truncate flex flew-row gap-2 m-auto"
              onClick={() =>
                callback(returnURL.split("?")[0] + "?page=" + pageNumber)
              }
            >
              <div className="text-[#3D5F7B] font-semibold underline truncate">
                {displayText}
              </div>
            </button>
          </div>
          <div className="m-auto">
            <a
              href={`/citation?path=${returnURL.split("?")[0]}`}
              target="_blank"
              rel="noreferrer"
              className="text-[#3D5F7B] font-semibold underline"
            >
              <svg
                width="16"
                height="15"
                viewBox="0 0 16 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.1673 13.3333H2.50065V1.66667H8.33398V0H2.50065C1.57565 0 0.833984 0.75 0.833984 1.66667V13.3333C0.833984 14.25 1.57565 15 2.50065 15H14.1673C15.084 15 15.834 14.25 15.834 13.3333V7.5H14.1673V13.3333ZM10.0007 0V1.66667H12.9923L4.80065 9.85833L5.97565 11.0333L14.1673 2.84167V5.83333H15.834V0H10.0007Z"
                  fill="#3D5F7B"
                />
              </svg>
            </a>
          </div>
        </div>
      </div>

      <div>
        <div className="flex flex-row gap-2">
          {pageNumber && (
            <>
              <div className="m-auto font-semibold">Page: </div>
              <div className="grow">{pageNumber}</div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProposalCard;
