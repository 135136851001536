import { msalInstance } from "../components/authenticator/azureAAD/Authenticator";
import { AIChatResponseHistory } from "../models/aichatresponsehistory";

function submitChatFeedback(
  chatHistory: AIChatResponseHistory[],
  section_tab: string,
  proposal_section_type?: string
) {
  if (chatHistory.length > 0) {
    let returnValue = true;

    const accounts = msalInstance.getAllAccounts();
    const userId = accounts[0].username;

    const body =
      `{
      "user_id": "` +
      userId +
      `",
      "chat_messages": ` +
      JSON.stringify(chatHistory) +
      `,"section_tab":"` +
      section_tab +
      `", 
      "proposal_section_type":"` +
      proposal_section_type +
      `"
      }`;

    // console.log("Submit Chat Feedback");
    // console.log(body);

    (async () => {
      try {
        const resp = await fetch(process.env.REACT_APP_CHAT_FEEDBACK_URL!, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
          body,
        });
        if (!resp.ok || !resp.body) {
          throw resp.statusText;
        }

        returnValue = true;
      } catch (err) {
        console.log("save chat feedback error");
        console.log(err);
        returnValue = false;
      }

      return returnValue;
    })();
  }
}

export default submitChatFeedback;
