import { InteractiveBrowserCredential } from "@azure/identity";
import { BlobServiceClient } from "@azure/storage-blob";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

const isImage = (filename: string) => {
  const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp", "tiff", "webp"];
  const extension = filename?.split("?")[0].split(".").pop()?.toLowerCase();
  return imageExtensions.includes(extension!);
};

const Citation = () => {
  const [searchParams] = useSearchParams();
  const [linkURL, setLinkURL] = useState<string>("");
  const blobName = searchParams.get("path");

  useEffect(() => {
    const getDocumentToShow = async (
      storageAccountName: string,
      containerName: string,
      blobPath: string,
      blobName: string
    ) => {
      const signInOptions = {
        // the client id is the application id, from your earlier app registration
        clientId: process.env.REACT_APP_AZURE_AAD_APP_CLIENT_ID,
        // this is your tenant id - the id of your azure ad tenant. available from your app registration overview
        tenantId: process.env.REACT_APP_AZURE_AAD_APP_TENANT_ID,
      };

      const blobServiceClient = new BlobServiceClient(
        "https://" + storageAccountName + ".blob.core.windows.net/",
        new InteractiveBrowserCredential(signInOptions)
      );

      const containerClient =
        blobServiceClient.getContainerClient(containerName);

      const blobClient = containerClient.getBlobClient(
        decodeURI(blobPath + blobName.split("?")[0])
      );

      let downloaded = await blobClient.download();

      const blob = (await downloaded.blobBody) as Blob;

      var fileLink = document.createElement("a");
      fileLink.href = URL.createObjectURL(blob);
      fileLink.download = blobName.split("/")[blobName.split("/").length - 1];
      fileLink.click();
    };

    if (blobName && blobName.length > 0) {
      getDocumentToShow(
        "stairfpdev142413",
        isImage(blobName) ? "doc-extracts" : "documents",
        isImage(blobName) ? "Images/" : "",
        blobName
      );
    }
  }, [blobName]);

  return (
    <div className="flex flex-col w-full min-w-0">
      <div className="mx-auto w-10/12 text-center pt-4 pb-2 border-b border-[#F2F2F2]">
        <div className="truncate">
          Your link should be downloading now. If your download has not started
          please click <a href={linkURL}>here</a>.
        </div>
      </div>
    </div>
  );
};

export default Citation;
