export const geography = [
  {
    displayText: "North East",
    indexName: "North East",
  },
  {
    displayText: "South East",
    indexName: "South East",
  },
  {
    displayText: "Midwest",
    indexName: "Midwest",
  },
  {
    displayText: "Mid-Atlantic",
    indexName: "Mid-Atlantic",
  },
  {
    displayText: "Texas Mountain",
    indexName: "Texas Mountain",
  },
  {
    displayText: "Pacific Northwest",
    indexName: "Pacific Northwest",
  },
  {
    displayText: "West",
    indexName: "West",
  }
];




 



