import { CitationType } from "../../enums/CitationTypes";

interface CitationCardProps {
  citationType: CitationType | undefined;
  citation: string;
  docPath: string | null;
  displayText: string;
  index: string;
  callback: (value: string) => void;
}

const CitationCard: React.FC<CitationCardProps> = ({
  citationType,
  citation,
  docPath,
  displayText,
  index,
  callback,
}) => {
  const isImage = (filename: string) => {
    const imageExtensions = [
      "jpg",
      "jpeg",
      "png",
      "gif",
      "bmp",
      "tiff",
      "webp",
    ];
    const extension = filename?.split(".")?.pop()?.toLowerCase();
    return imageExtensions.includes(extension!);
  };

  const citationURL = (value: string) => {
    // extract page number from returnURL
    let returnURL = value;
    let schemedHref = returnURL;
    if (!/^https?:\/\//i.test(returnURL)) {
      schemedHref = "https://" + returnURL;
    }
    const urlParams = new URL(schemedHref).searchParams;
    const pageNumber = urlParams.get("page"); // page number

    const regex = RegExp(/href="([^<]+)"/, "gi");

    let m;

    do {
      m = regex.exec(returnURL);

      if (m) {
        returnURL = m[0].replace('href="', "").replace('"', "");
      }
    } while (m);

    returnURL = returnURL.replace("source:", "").trim();

    const isFileImage = isImage(returnURL);
    const svg = isFileImage ? <ImageSVG /> : <DocumentSVG />;

    return { returnURL, svg, pageNumber };
  };

  const ImageSVG = () => (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.8333 4.66667V16.3333H4.16667V4.66667H15.8333ZM15.8333 3H4.16667C3.25 3 2.5 3.75 2.5 4.66667V16.3333C2.5 17.25 3.25 18 4.16667 18H15.8333C16.75 18 17.5 17.25 17.5 16.3333V4.66667C17.5 3.75 16.75 3 15.8333 3ZM11.7833 10.3833L9.28333 13.6083L7.5 11.45L5 14.6667H15L11.7833 10.3833Z"
        fill="#3D5F7B"
      />
    </svg>
  );

  const DocumentSVG = () => (
    <svg
      width="16"
      height="15"
      viewBox="0 0 16 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.1673 13.3333H2.50065V1.66667H8.33398V0H2.50065C1.57565 0 0.833984 0.75 0.833984 1.66667V13.3333C0.833984 14.25 1.57565 15 2.50065 15H14.1673C15.084 15 15.834 14.25 15.834 13.3333V7.5H14.1673V13.3333ZM10.0007 0V1.66667H12.9923L4.80065 9.85833L5.97565 11.0333L14.1673 2.84167V5.83333H15.834V0H10.0007Z"
        fill="#3D5F7B"
      />
    </svg>
  );

  const { returnURL, svg, pageNumber } = citationURL(citation);

  const extractDocumentType = (value: string) => {
    const pathItems = value.split("/");

    const detectedFolderName: string = pathItems[3];

    switch (detectedFolderName) {
      case "007_WSP Deliverables":
        return "Internal";
      default:
        return "External";
    }
  };

  const extractDocumentCategory = (value: string) => {
    const pathItems = value.split("/");

    const detectedFolderName: string = pathItems[3];

    switch (detectedFolderName) {
      case "007_WSP Deliverables":
        return "WSP Documents";
      case "006_Conferences":
        return "Conference";
      default:
        return "Mining KB";
    }
  };

  return (
    <div
      className="bg-light-blue p-6 whitespace-nowrap flex flex-col gap-2 my-auto min-w-0"
      key={citation}
    >
      <div>
        <div className="flex flex-row gap-2 min-w-0">
          <div className="grow min-w-0">
            <button
              type="button"
              className="w-full truncate flex flew-row gap-2 m-auto"
              onClick={() => callback(returnURL)}
            >
              {citationType === CitationType.Document && (
                <div className="text-blue font-medium">{index}</div>
              )}
              <div className="text-[#3D5F7B] font-semibold underline truncate">
                {displayText}
              </div>
            </button>
          </div>
          <div className="m-auto">
            <a
              href={`/citation?path=${returnURL.split("?")[0]}`}
              target="_blank"
              rel="noreferrer"
              className="text-[#3D5F7B] font-semibold underline"
            >
              {svg}
            </a>
          </div>
        </div>
      </div>

      <div>
        <div className="flex flex-row gap-2">
          {pageNumber && (
            <>
              <div className="m-auto font-semibold">Page: </div>
              <div className="grow">{pageNumber}</div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default CitationCard;
