export const corporate_data = [
  {
    displayText: "Client Care Program",
    indexName: "Client Care Program",
  },
  {
    displayText: "Corporate Accomplishments",
    indexName: "Corporate Accomplishments",
  },
  {
    displayText: "ESG Reports",
    indexName: "ESG Reports",
  },
  {
    displayText: "Future Ready",
    indexName: "Future Ready",
  },
  {
    displayText: "Rail",
    indexName: "Rail",
  },
  {
    displayText: "Tunnel",
    indexName: "Tunnel",
  },
  {
    displayText: "Vision Zero",
    indexName: "Vision Zero",
  }
];







