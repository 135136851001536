import { useEffect, useRef, useState } from "react";
import { SectionTabs } from "../enums/SectionTabs";
import { getAadAuthToken as getAuthToken } from "../components/authenticator/azureAAD/Authenticator";
import { ProposalSearchResponse } from "../models/proposalsearchresponse";
import { ErrorMessage } from "../enums/ErrorMessage";

function useGetProposalsResponse(
  message: string,
  searchType: SectionTabs,
  region: string,
  clientName: string,
  primaryEndMarket: string,
  corporateContent: string,
  proposalSection: string,
  proposalSectionType: string,
  sectionTab: string,
  numResults: number
) {
  const [proposalsData, setProposalsData] = useState<ProposalSearchResponse | null>(
    null
  );
  const [proposalsLoading, setProposalsLoading] = useState(false);
  const [proposalsError, setProposalsError] = useState<string | null>(null);
  const proposalsAbortControllerRef = useRef<AbortController>(
    new AbortController()
  );

  useEffect(() => {
    (async () => {
      if (message.length > 0) {
        try {
          setProposalsLoading(true);

          const messageText = JSON.stringify(
            message.replace(/\r\n|\n|\r/gi, " ")
          );

          const body =
            `{
                "role": "user",
                "content": ` +
            messageText +
            `,

                "region": "` +
            region +
            `", 
                "client_name": "` +
            clientName +
            `", 
                "primary_end_market": "` +
            primaryEndMarket +
            `", 
                "corporate_content": "` +
            corporateContent +
            `", 
                "proposal_section": ` +
            proposalSection +
            `,
                "proposal_section_type": "` +
            proposalSectionType +
            `",  
                "section_tab": "` +
            searchType +
            `"}`;

          console.log("body");
          console.log(body);

          const authToken = await getAuthToken();
          const resp = await fetch(process.env.REACT_APP_CHAT_PROPOSALS_SEARCH_URL!, {
            method: "POST",
            signal: proposalsAbortControllerRef.current.signal,
            headers: {
              Authorization: `Bearer ${authToken}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
            },
            body,
          })
            .then(async (resp: any) => {
              const response: ProposalSearchResponse = await resp.json();

              // When no top proposal is found from getproposals or return 403 Forbidden, 
              // or 404 Not Found, 
              // or 400 Bad Request
              // an error message should will be shown in the UI.
              if((resp.status >= 400 && resp.status <= 404) || response.length === 0) {
                throw ErrorMessage.NoProposal
              }

              setProposalsLoading(false);
              setProposalsData(response);
            })
            .catch((err) => {
              console.log("Proposal retrieval error");
              console.log(err);
              if(err === ErrorMessage.NoProposal) {
                setProposalsError(ErrorMessage.NoProposal);
              } else if (!proposalsAbortControllerRef.current.signal.aborted) {
                setProposalsError(ErrorMessage.ResponseStopped);
              } else {
                setProposalsError("The get proposal response has been stopped.");
              }
              setProposalsLoading(false);
            });
        } catch (err) {
          console.log("Proposals retrieval error");
          console.log(err);

          setProposalsLoading(false);
        }
      }
    })();
  }, [
    searchType,
    region,
    clientName,
    primaryEndMarket,
    corporateContent,
    proposalSection,
    message, // we should watch message change to decide if we send get proposals request, instead of watching sectionTab change
    numResults,
  ]);

  return {
    proposalsData,
    proposalsLoading,
    proposalsError,
    proposalsAbortControllerRef,
  };
}

export default useGetProposalsResponse;
