import React, { RefAttributes, useEffect, useRef, useState } from "react";
import {
  WSPRule,
  ChatQuestionCard,
  ChatQuestionDock,
  StopRespondingButton,
} from "../../components";
import useGetAIChatResponse from "../../hooks/getAIChatResponse";
import { ChatContextType } from "../../contexts/ChatContext/ChatContextType";
import { ChatContext } from "../../contexts/ChatContext/ChatContextProvider";
import { useNavigate } from "react-router-dom";
import loadingimage from "../../assets/images/Loading.gif";
import { v4 as uuidv4 } from "uuid";
import CSS from "csstype";

import DocumentViewer from "../../components/documentviewer/documentviewer";
import { AIChatResponseHistory } from "../../models/aichatresponsehistory";
import { FeedbackType } from "../../enums/FeedbackTypes";
import ErrorCard from "../../components/errorcard/errorcard";
import submitChatFeedback from "../../services/submitChatFeedback";
import { PinnedChat } from "../../models/pinnedchat";
import submitChatHistory from "../../services/submitChatHistory";
import { SearchType } from "../../enums/SearchTypes";
import { SectionTabs } from "../../enums/SectionTabs";
import useGetProposalsResponse from "../../hooks/getProposalsResponse";
import { getIndexesBySearchFilterTypes } from "../../utils/getIndexesBySearchFilterTypes.util";
import { SearchFilterTypes } from "../../enums/SearchFilterTypes";

const transformHistory = (historyObject: AIChatResponseHistory[]) => {
  let responseObject: { message: string; messageType: string }[] = [];
  if (historyObject !== undefined) {
    historyObject.forEach((item) => {
      responseObject.push({
        message: item.question,
        messageType: "HumanMessage",
      });
      responseObject.push({ message: item.output, messageType: "AIMessage" });
    });
  }

  return responseObject;
};

const chatSuggestions: string[] = [
  "Tell me more.",
  "Give me a shorter summary.",
  "What are things to watch out for in a similar project?",
];

const Chat = () => {
  const navigate = useNavigate();
  const chatContext = React.useContext<ChatContextType | null>(ChatContext);
  const [questionId, setQuestionId] = useState<string>("");
  // chat response
  const {
    data,
    loading,
    abortControllerRef,
    streaming,
    streamResponse,
    error,
  } = useGetAIChatResponse(
    chatContext ? chatContext?.chat.question : "",
    chatContext
      ? JSON.stringify(transformHistory(chatContext.chatHistory))
      : "",
    chatContext ? chatContext?.chat.proposal : "",
    chatContext ? chatContext?.indexes : [],
    chatContext ? chatContext?.sessionId : uuidv4().replaceAll("-", ""),
    questionId
  );

  const {
    proposalsData,
    proposalsLoading,
    proposalsError,
    proposalsAbortControllerRef,
  } = useGetProposalsResponse(
    chatContext ? chatContext?.chat.question : "",
    SectionTabs.Kickstart,
    getIndexesBySearchFilterTypes(
      (chatContext ? chatContext?.indexes : []).toString(),
      SearchFilterTypes.GEOGRAPHY
    ),
    getIndexesBySearchFilterTypes(
      (chatContext ? chatContext?.indexes : []).toString(),
      SearchFilterTypes.CLIENT_NAME
    ),
    getIndexesBySearchFilterTypes(
      (chatContext ? chatContext?.indexes : []).toString(),
      SearchFilterTypes.PRIMARY_END_MARKET
    ),
    getIndexesBySearchFilterTypes(
      (chatContext ? chatContext?.indexes : []).toString(),
      SearchFilterTypes.CORPORATE_CONTENT
    ),
    JSON.stringify(
      (chatContext ? chatContext?.chat.proposal : "").replace(
        /\r\n|\n|\r/gi,
        " "
      )
    ),
    chatContext ? chatContext?.proposalSectionType : "",
    chatContext?.searchType === SearchType.Kickstart
      ? SectionTabs.Kickstart
      : SectionTabs.ProposalSections,
    5
  );

  const latestAnswerRef = useRef<null | HTMLDivElement>(null);
  const [documentToShow, setDocumentToShow] = useState<string>("");

  const [dataToDisplay, setDataToDisplay] = useState<AIChatResponseHistory[]>(
    []
  );

  // generate a unique question id
  useEffect(() => {
    setQuestionId(uuidv4().replaceAll("-", ""));
  }, []);

  // save data to history
  useEffect(() => {
    if (data && data.question) {
      let newHistoryItem: AIChatResponseHistory = {
        questionId: questionId,
        sessionId: chatContext?.sessionId,
        question: data.question,
        output: data.output,
        indexes: chatContext ? chatContext?.indexes : [],
        pin: false,
        proposals: proposalsData,
        feedbackType: FeedbackType.None,
      } as AIChatResponseHistory;

      // console.log("Adding new history item");
      // console.log(newHistoryItem);

      chatContext?.addToChatHistory(newHistoryItem);
    }
  }, [data, proposalsData]);

  useEffect(() => {
    if (chatContext && chatContext.chatHistory) {
      setDataToDisplay(chatContext.chatHistory);
    }
  }, [chatContext?.chatHistory]);

  const handleFollowUpQuestion = (value: string) => {
    chatContext?.saveChat({
      question: value,
      history: "",
      proposal: chatContext.chat.proposal,
    });
  };

  useEffect(() => {
    if (dataToDisplay && dataToDisplay.length > 0) {
      if (latestAnswerRef.current !== null) {
        latestAnswerRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    }
  }, [loading, dataToDisplay, chatContext?.activeIndex]);

  const handleCitationChange = (value: string) => {
    if (value.length > 0) {
      if (value === documentToShow) {
        setDocumentToShow("");
      } else {
        setDocumentToShow("");
        setDocumentToShow(value);
      }
    }
  };

  const handlePinConversation = (index: number) => {
    // console.log("Pin conversation");
    // console.log("index: ", index);
    // console.log(chatContext?.chatHistory);

    if (chatContext && chatContext.chatHistory) {
      chatContext.chatHistory[index].pin = !chatContext.chatHistory[index].pin;

      const sesstionId = chatContext.chatHistory[0].sessionId;
      const questionId = chatContext.chatHistory[0].questionId;

      // before add or remove a pinned conversation and then save to db
      // we should check if the id of pinned conversation exists in the chat history
      let id = "";
      if (chatContext?.pinnedChatSessionsData) {
        Object.entries(chatContext?.pinnedChatSessionsData).forEach((item) => {
          return item.forEach((subItem) => {
            if (typeof subItem === "object") {
              return subItem.forEach((chatItem) => {
                if (
                  chatItem.sessionId === sesstionId &&
                  chatItem.questionId === questionId
                ) {
                  id = chatItem.id ?? "";
                  return;
                }
              });
            }
          });
        });
        // if the id of pinned conversation exists in the chat history,
        // we should set it for the pinned conversation
        // so that the backend will not create a new record in db, just update the exsiting record
        if (id) {
          chatContext.chatHistory.forEach((chatItem) => {
            if (!chatItem.id) {
              chatItem.id = id;
            }
          });
        }
      }

      chatContext?.addPinnedConversation(chatContext.chatHistory, index);
    }
  };

  //TODO: move it to shared css styling file once the position of button gets confirmed
  const stopBtnStyle: CSS.Properties = {
    backgroundColor: "#fff",
    position: "fixed",
    bottom: "2%",
    left: "50%",
  };

  const saveFeedbackToDB = () => {
    const submitChatFeedbackResponse = submitChatFeedback(
      dataToDisplay,
      chatContext?.searchType === SearchType.Kickstart
        ? SectionTabs.Kickstart
        : SectionTabs.ProposalSections,
      chatContext?.proposalSectionType
    );

    if (dataToDisplay.filter((item) => item.pin === true).length > 0) {
      const submitChatHistoryResponse = submitChatHistory(
        {
          initialMessage: dataToDisplay[0].question,
          sessionId: dataToDisplay[0].sessionId,
          chatMessages: dataToDisplay,
        } as PinnedChat,
        chatContext?.searchType === SearchType.Kickstart
          ? SectionTabs.Kickstart
          : SectionTabs.ProposalSections,
        chatContext?.proposalSectionType,
        chatContext?.chat.proposal
      );
    }
  };

  return loading ? (
    <div className="h-full w-full flex">
      <div className="flex flex-col m-auto w-4/5 pt-6 text-center">
        <div>
          <h4 className="text-red font-semibold">
            {chatContext?.chat.question}
          </h4>
        </div>
        <div className="m-auto">
          <img src={loadingimage} />
        </div>
        <div className="mb-6">Loading</div>
        <div>
          <StopRespondingButton
            abortControllerRef={[abortControllerRef]}
          ></StopRespondingButton>
        </div>
      </div>
    </div>
  ) : (
    <>
      <div className="flex flex-col w-full">
        <div className="grow w-full">
          <div className="flex flex-col w-full min-w-0">
            <div className="mx-5 w-full text-center pt-4 pb-2 border-b border-[#F2F2F2]">
              <div className="truncate">
                Home /{" "}
                {dataToDisplay.length > 0
                  ? dataToDisplay[0].question
                  : chatContext?.chat.question}
              </div>
            </div>
            <div className="mx-5 w-full flex flex-row gap-4 min-w-0">
              <div
                className={documentToShow ? "w-1/2 flex" : "w-full flex m-auto"}
              >
                <div className="flex flex-col pt-6 overflow-hidden m-auto w-full">
                  <div>
                    <div className="grid grid-cols-1 gap-4">
                      {((!loading &&
                        !streaming &&
                        dataToDisplay.length < 1 &&
                        !abortControllerRef.current.signal.aborted) ||
                        error) && (
                        <ErrorCard
                          title={"Something went wrong"}
                          error={error}
                        />
                      )}

                      {!loading &&
                        !streaming &&
                        dataToDisplay.length < 1 &&
                        abortControllerRef.current.signal.aborted && (
                          <ErrorCard title="Response stopped" error={error} />
                        )}
                      {dataToDisplay.map((item, i) => (
                        <div
                          key={item.question}
                          ref={
                            i === chatContext?.activeIndex
                              ? latestAnswerRef
                              : null
                          }
                        >
                          <ChatQuestionCard
                            chatMessage={item}
                            streamResponse={null}
                            streaming={streaming}
                            callback={handleCitationChange}
                            pinnedConversationCallback={handlePinConversation}
                            saveFeedbackCallback={saveFeedbackToDB}
                            proposals={item.proposals}
                            proposalError={proposalsError}
                            index={i}
                          />
                          <div className="pb-2">
                            <WSPRule />
                          </div>
                        </div>
                      ))}

                      {streamResponse && streaming && (
                        <>
                          <ChatQuestionCard
                            chatMessage={
                              {
                                question: chatContext
                                  ? chatContext?.chat.question
                                  : "",
                                output: "",
                                indexes: chatContext
                                  ? chatContext?.indexes
                                  : [],
                                pin: false,
                              } as AIChatResponseHistory
                            }
                            streamResponse={streamResponse}
                            streaming={streaming}
                            callback={handleCitationChange}
                            pinnedConversationCallback={handlePinConversation}
                            saveFeedbackCallback={saveFeedbackToDB}
                            index={0}
                          />
                        </>
                      )}
                      <div>
                        <ChatQuestionDock
                          FollowUpQuestions={chatSuggestions}
                          callback={handleFollowUpQuestion}
                          abortControllerRef={[abortControllerRef]}
                          setQuestionId={setQuestionId}
                          proposalSection={
                            chatContext?.chat.proposal
                              ? chatContext?.chat.proposal
                              : dataToDisplay[0]?.proposal_section ?? ""
                          }
                          proposalSectionDroplistDisabled={true}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {documentToShow && (
                <div className="flex w-1/2">
                  <div className="flex flex-col bg-light-gray fixed h-[94vh] w-2/6">
                    <div className="ml-auto">
                      <button
                        type="button"
                        className="p-2"
                        onClick={() => setDocumentToShow("")}
                      >
                        <svg
                          width="12"
                          height="12"
                          viewBox="0 0 12 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M11.8327 1.34163L10.6577 0.166626L5.99935 4.82496L1.34102 0.166626L0.166016 1.34163L4.82435 5.99996L0.166016 10.6583L1.34102 11.8333L5.99935 7.17496L10.6577 11.8333L11.8327 10.6583L7.17435 5.99996L11.8327 1.34163Z"
                            fill="#3D5F7B"
                          />
                        </svg>
                      </button>
                    </div>
                    <div
                      className={
                        documentToShow.includes("pdf")
                          ? "border w-full h-full"
                          : "border w-full"
                      }
                    >
                      <DocumentViewer blobName={documentToShow} />
                    </div>
                  </div>
                </div>
              )}
            </div>
            {streaming && (
              <div className={"z-50"} style={stopBtnStyle}>
                <StopRespondingButton
                  abortControllerRef={[abortControllerRef]}
                ></StopRespondingButton>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Chat;
