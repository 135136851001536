import { PromptSnippet } from "../models/promptsnippet";

export const guidedPrompt: PromptSnippet[] = [
  {
    displayName: "Executive Summary",
    text: "Executive Summary",
    value: "Based on the RFP, provide an executive summary for a proposal for <type of project> project for <insert client name>. The summary should include: <insert summary sections>  Highlight WSP's experience in <type of service >, <type of service >, <type of service>, the Future Ready approach, and how this project will enhance <type of scope>."
  },
  {
    displayName: "Technical Approach",
    text: "Technical Approach",
    value: "Draft a technical approach for a <type of service> project, detailing WSP's engineering methodology and <insert any other methodologies>, <insert any approaches>, <insert any differentiators>."
  },
  {
    displayName: "Project Approach",
    text: "Project Approach",
    value: "Provide a project approach for a <type of service> project. Focusing on WSP's approach to <insert project scope>, <insert focus areas>."
  },
  {
    displayName: "Cover Letter",
    text: "Cover Letter",
    value: "Based on the context, create  a detailed cover letter on <type of service> and 3 differentiators around <put in the differentiators>. <First Last Name> is the project manager and should be highlighted in the cover letter. You must include the following in the cover letter: Start with a brief introduction that states the purpose of the letter and the proposal being submitted. Mention the project name and any relevant reference numbers.  Provide a brief overview of your company, highlighting your expertise, experience, and any unique qualifications that make your company a strong candidate for the project, and <other required statements/considerations>."
  },
  {
    displayName: "Project Management Approach",
    text: "Project Management Approach",
    value: "Based on the RFP write a project management approach for a <type of service> project, focusing on WSP's approach to <insert focus areas>."
  },
  {
    displayName: "Subconsultant Utilization",
    text: "Subconsultant Utilization",
    value: "Provide a detailed explanation of WSP's approach to managing and utilizing <specific group, such as subconsultants> to achieve the <specific goal, such as DBE goals or project objectives> for this <contract or client>. The explanation should include specific strategies for ensuring meaningful participation, such as <methods of accountability or communication>, and describe how work will be allocated among the <specific group]> Please include any relevant metrics or tools, such as utilization tables or performance tracking, if applicable."
  },
  {
    displayName: "Use of DBEs",
    text: "Use of DBEs",
    value: "How does WSP determine the appropriate <stakeholders/methodology> to meet <specific goals or requirements> for this <project/client> ? Please include <specific details, roles, or components> and provide a breakdown of <relevant information, such as percentages or contributions>, along with any relevant examples or details on how these decisions were made, if applicable."
  },
  {
    displayName: "Relevant Experience",
    text: "Relevant Experience",
    value: "Showcase examples where we applied solutions similar to <type of experiences>. Include <types of techniques>, <types of technologies>, <types of experiences> aligning with the technical requirements of this RFP."
  },
  {
    displayName: "Community Engagement",
    text: "Community Engagement",
    value: "Based on the RFP provided, draft a proposal section on WSP's approach to community engagement for <type of service> project. Emphasize the importance of <insert focus areas>."
  },
  {
    displayName: "Firm Qualifications",
    text: "Firm Qualifications",
    value: "Provide an overview of WSP's project experience in <insert project experience>. This should also highlight WSP's <insert delivery criteria>. Include any awards or recognitions we have received for relevant projects, particularly those that highlight our excellence in <type of service>."
  },
  {
    displayName: "Win Themes",
    text: "Differentiators",
    value: "Provide Win Themes for WSP's ability to <type of delivery methods or services> that set WSP apart in <type of service> focusing on examples from previous successful projects."
  }
];

export const promptSnippets: PromptSnippet[] = [
  {
    text: "Find projects that...",
    value: "Find projects that ",
  },
  {
    text: "Respond to an RFP that...",
    value: "Respond to an RFP that ",
  },
  {
    text: "Search recent projects for...",
    value: "Search recent projects for ",
  },
];

export const promptGroup1: PromptSnippet[] = [
  {
    text: "How does the Q system account for high mining induced stress?",
    value: "How does the Q system account for high mining induced stress?",
  },
  {
    text: "What is shakedown and how can it be prevented?",
    value: "What is shakedown and how can it be prevented?",
  },
  {
    text: "What is the difference between a strainburst and a shear rupture?",
    value: "What is the difference between a strainburst and a shear rupture?",
  },
  {
    text: "How does rockburst damage impact production from cave mines?",
    value: "How does rockburst damage impact production from cave mines?",
  },
  {
    text: "What is the difference between strategic and tactical controls for rockbursting?",
    value: "What is the difference between strategic and tactical controls for rockbursting?",
  },
  {
    text: "How can brittle rock mass behaviour be captured in continuum models?",
    value: "How can brittle rock mass behaviour be captured in continuum models?",
  },
];

export const promptGroup2: PromptSnippet[] = [
  {
    text: "How to log veined rock core?",
    value: "How to log veined rock core?",
  },
  {
    text: "How to estimate corrosion rates for steel ground support in mines?",
    value: "How to estimate corrosion rates for steel ground support in mines?",
  },
  {
    text: "How to calculate the maximum stress at the boundary of a tunnel?",
    value: "How to calculate the maximum stress at the boundary of a tunnel?",
  },
];

export const promptGroup3: PromptSnippet[] = [
  {
    text: "What are key challenges in the DMLZ at Grasberg?",
    value: "What are key challenges in the DMLZ at Grasberg?",
  },
  {
    text: "What are the common vein types encountered at Red Chris and their implications for caving?",
    value: "What are the common vein types encountered at Red Chris and their implications for caving?",
  },
  {
    text: "What are possible challenges for caving with the silicic cap domain at the Hu'u project?",
    value: "What are possible challenges for caving with the silicic cap domain at the Hu'u project?",
  },
];
