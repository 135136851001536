import { useState } from "react";
import { ChatContextType, iChatContext } from "./ChatContextType";
import React from "react";
import { client_name, corporate_data, end_market, geography } from "../../data";
import { v4 as uuidv4 } from "uuid";
import { FilterScope } from "../../enums/FilterScope";
import { SearchType } from "../../enums/SearchTypes";
import { AIChatResponseHistory } from "../../models/aichatresponsehistory";
import { PinnedChat } from "../../models/pinnedchat";
import submitChatHistory from "../../services/submitChatHistory";
import { SectionTabs } from "../../enums/SectionTabs";

export const ChatContext = React.createContext<ChatContextType | null>(null);

const ChatContextProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [sessionId, setSessionId] = useState<string>(
    uuidv4().replaceAll("-", "")
  );
  const [chat, setChat] = useState<iChatContext>({
    question: "",
    history: "",
    proposal: "",
  });
  const [indexes, setIndexes] = useState<string[]>(
    [...client_name, ...corporate_data, ...end_market, ...geography].map(
      (item) => item.indexName
    )
  );
  const [proposalSectionType, setProposalSectionType] = useState<string>("");

  const [allSelected, setAllSelected] = useState<boolean>(true);
  const [pinnedConversations, setPinndedConversations] = useState<PinnedChat[]>(
    []
  );
  const [activePinnedConversation, setActivePinnedConversation] =
    useState<AIChatResponseHistory | null>(null);
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [filterScope, setFilterScope] = useState<FilterScope>(
    FilterScope.DocumentsAndImages
  );
  const [searchType, setSearchType] = useState<SearchType>(
    SearchType.Kickstart
  );
  const [chatHistory, setChatHistory] = useState<AIChatResponseHistory[]>([]);
  const [chatHistoryUpdateTimestamp, setChatHistoryUpdateTimestamp] =
    useState<string>("");
  const [pinnedChatSessionsData, setPinnedChatSessionsData] =
    useState<Partial<Record<string, AIChatResponseHistory[]>>>();

  const savePinnedChatSessionsData = (
    pinnedChatSessionsData:
      | Partial<Record<string, AIChatResponseHistory[]>>
      | undefined
  ) => {
    setPinnedChatSessionsData(pinnedChatSessionsData);
  };

  const saveChat = (newValues: iChatContext) => {
    setChat(newValues);
  };

  const saveIndexes = (newIndexes: string[]) => {
    setIndexes(newIndexes);
  };

  const saveProposalSectionType = (type: string) => {
    setProposalSectionType(type);
  };

  const saveAllSelected = (value: boolean) => {
    setAllSelected(value);
  };

  const saveChatAndIndexes = (
    chat: iChatContext,
    newIndexes: string[],
    filterScope?: FilterScope
  ) => {
    setChat(chat);
    setIndexes(newIndexes);
    if (filterScope) {
      setFilterScope(filterScope);
    }
  };

  const startNewSession = () => {
    setSessionId(uuidv4().replaceAll("-", ""));
    setActivePinnedConversation(null);
    setChatHistory([]);
    setProposalSectionType("");
    setSearchType(SearchType.Kickstart);
  };

  // add the conversation to the list of pinned conversations
  const addPinnedConversation = (
    conversation: AIChatResponseHistory[],
    activeIndex: number
  ) => {
    // console.log("Add conversation to pinned conversations");
    // console.log(conversation);

    let newPinnedChat: PinnedChat = {
      initialMessage: conversation[0].question,
      sessionId: conversation[0].sessionId,
      chatMessages: conversation,
    } as PinnedChat;

    let newPinnedConversations = [...pinnedConversations];

    const existingItemIndex = newPinnedConversations.findIndex(
      (item) => item.sessionId === newPinnedChat.sessionId
    );

    if (existingItemIndex > -1) {
      newPinnedConversations.splice(existingItemIndex, 1);
    }

    newPinnedConversations.push(newPinnedChat);

    setPinndedConversations(newPinnedConversations);

    const submitChatHistoryResponse = submitChatHistory(
      newPinnedChat,
      searchType === SearchType.Kickstart
        ? SectionTabs.Kickstart
        : SectionTabs.ProposalSections,
      proposalSectionType,
      chat.proposal
    );

    if (submitChatHistoryResponse) {
      setTimeout(() => {
        setChatHistoryUpdateTimestamp(Date.now().toString());
      }, 500);
    }
  };

  // change the active pinned conversation to a different conversaation
  const saveActivePinnedConversation = (
    conversation: PinnedChat,
    activeIndex: number
  ) => {
    console.log("Change active pinned convesaton");
    console.log("index: ", activeIndex);
    console.log(conversation);

    setChat({
      question: "",
      history: "",
      proposal: "",
    });

    setChatHistory(conversation.chatMessages);
    setIndexes(conversation.chatMessages[activeIndex].indexes);
    setActiveIndex(activeIndex);
    setSearchType(
      conversation.chatMessages[conversation.chatMessages.length - 1]
        .section_tab === SectionTabs.ProposalSections
        ? SearchType.ProposalSections
        : SearchType.Kickstart
    );
    setProposalSectionType(
      conversation.chatMessages[conversation.chatMessages.length - 1]
        ?.proposal_section_type
    );
  };

  const saveFilterScope = (scope: FilterScope) => {
    setFilterScope(scope);
  };

  const saveSearchType = (searchType: SearchType) => {
    setSearchType(searchType);
  };

  const addToChatHistory = (historyItem: AIChatResponseHistory) => {
    let newChatHistory = [...chatHistory];

    const existingItemIndex = newChatHistory.findIndex(
      (item) => item.questionId === historyItem.questionId
    );

    if (existingItemIndex > -1) {
      newChatHistory.splice(existingItemIndex, 1);
    }

    newChatHistory.push(historyItem);

    setChatHistory(newChatHistory);
    setActiveIndex(newChatHistory.length - 1);
  };

  const saveChatHistoryUpdateTimestamp = (value: string) => {
    setChatHistoryUpdateTimestamp(value);
  };

  const saveSessionId = (value: string) => {
    setSessionId(value);
  };

  return (
    <ChatContext.Provider
      value={{
        sessionId,
        saveSessionId,
        chat,
        saveChat,
        indexes,
        saveIndexes,
        proposalSectionType,
        saveProposalSectionType,
        allSelected,
        saveAllSelected,
        saveChatAndIndexes,
        startNewSession,
        activePinnedConversation,
        saveActivePinnedConversation,
        pinnedConversations,
        addPinnedConversation,
        activeIndex,
        filterScope,
        saveFilterScope,
        searchType,
        saveSearchType,
        chatHistory,
        addToChatHistory,
        chatHistoryUpdateTimestamp,
        saveChatHistoryUpdateTimestamp,
        pinnedChatSessionsData,
        savePinnedChatSessionsData,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};

export default ChatContextProvider;
